import { useIntl } from 'react-intl';
import classNames from 'classnames';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { GA4ItemListName, GA4ItemListType } from '@jsmdg/tracking';
import { MapViewProductTile } from '@jsmdg/yoshi';
import { type FragmentContext } from '../../../shared/types/fragmentContext';
import { type Product } from '../../../shared/types/searchResponse';
import { type TrackingListName } from '../../enums/trackingListName';
import { getProductTileMessages } from '../../helper/getProductTileMessages';
import { renderPrice } from '../../helper/renderPrice';
import { useOnProductTileClickAndImpression } from '../../hooks/useOnProductTileClickAndImpression';
import { usePriceClassName } from '../../hooks/usePriceClassName';
import { useProductTileData } from '../../hooks/useProductTileData';
import { useWishListToggle } from '../../hooks/useWishListToggle';
import styles from './MapViewModal.module.scss';

const messages = getProductTileMessages();

type MapViewProductTileWrapperProps = {
    readonly product: Product;
    readonly position: number;
    readonly lazyLoad: boolean;
    readonly trackingListName?: TrackingListName;
    readonly isListView?: boolean;
    readonly queryId?: string;
    readonly indexName?: string;
    readonly hasLocationFilter?: boolean;
};

const MapViewProductTileWrapper = ({
    hasLocationFilter,
    indexName,
    isListView,
    lazyLoad,
    position,
    product,
    queryId,
    trackingListName,
}: MapViewProductTileWrapperProps): JSX.Element => {
    const intl = useIntl();

    const { customerState, errorLogger, initialWishlist } = useFragmentContext<FragmentContext>();

    const {
        discountBadge,
        getLocationLabel,
        getParticipantsLabel,
        images,
        locale,
        onCloseServices,
        onServicesActionButtonClick,
        onToggleServices,
        originalPrice,
        price,
        url,
    } = useProductTileData(
        product,
        intl,
        {
            isListView,
            indexName,
            queryId,
            trackingListName,
            hasLocationFilter,
            isModalView: true,
        },
        messages,
    );

    const { onProductTileClick, setImpressionTrackedElement } = useOnProductTileClickAndImpression(
        product,
        position,
        intl,
        {
            isListView,
            indexName,
            queryId,
            trackingListName,
            hasLocationFilter,
            isModalView: true,
        },
    );

    const wishlistMessages = {
        a11yCloseErrorMessage: intl.formatMessage(messages.a11yCloseErrorMessage),
        tooManyWishlistArticlesError: intl.formatMessage(messages.tooManyWishlistArticlesError),
    };

    const { isOnWishlist, onWishlistButtonClick } = useWishListToggle(
        product,
        wishlistMessages,
        {
            customerState,
            errorLogger,
            initialWishlist,
        },
        GA4ItemListName.Category,
        GA4ItemListType.MapViewList,
        hasLocationFilter,
    );

    const a11yWishlistButton = isOnWishlist
        ? intl.formatMessage(messages.screenReaderTextForWishlistRemove)
        : intl.formatMessage(messages.screenReaderTextForWishlistAdd);

    const priceClassName = usePriceClassName(originalPrice);

    const { participants, rating, services, title } = product;

    return (
        <div
            className={classNames(styles.productTile, 'px-2x pt-2x')}
            data-testid="map-view-product-tile"
        >
            <MapViewProductTile
                name={title}
                price={renderPrice(price, intl)}
                originalPrice={renderPrice(originalPrice, intl)}
                images={images}
                url={url}
                locale={locale}
                rating={rating}
                services={
                    services && {
                        caption: intl.formatMessage(messages.servicesCaptionMessage),
                        body: services.body,
                        servicesActionButtonLabel: intl.formatMessage(
                            messages.servicesActionButtonMessage,
                        ),
                        onServicesActionButtonClick,
                        a11yCloseServicesLabel: intl.formatMessage(
                            messages.a11YCloseServicesMessage,
                        ),
                        onToggleServices,
                        onCloseServices,
                    }
                }
                lazyLoad={lazyLoad}
                maxParticipants={participants?.max}
                ref={setImpressionTrackedElement}
                locationLabel={getLocationLabel()}
                participantsLabel={getParticipantsLabel()}
                a11yPdpRedirectLabel={intl.formatMessage(messages.a11YPdpRedirection)}
                onProductClick={onProductTileClick}
                discountBadge={discountBadge}
                showWishlistButton
                toggleWishlist={onWishlistButtonClick}
                a11yWishlistButton={a11yWishlistButton}
                isOnWishlist={isOnWishlist}
                priceClassName={priceClassName}
            />
        </div>
    );
};

export { MapViewProductTileWrapper };
