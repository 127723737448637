/* eslint-disable import/no-unassigned-import */
import { type Dispatch, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import {
    Control,
    latLng,
    latLngBounds,
    type Map,
    map as Lmap,
    type MarkerClusterGroup,
    tileLayer,
} from 'leaflet';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import 'leaflet.markercluster/dist/leaflet.markercluster';
import { type FragmentContext } from '../../../shared/types/fragmentContext';
import { getProductTileMessages } from '../../helper/getProductTileMessages';
import {
    addMarkersToCluster,
    calculateMapView,
    cleanupMapRef,
    createIcon,
    createMarkerCluster,
    getDistanceToEdgeFromCenter,
    getMapContainer,
    getUrlParameters,
} from '../../helper/mapViewHelper';
import { type SearchReducerAction, SearchReducerActionType } from '../../reducers/searchReducer';
import { type CustomMapMarker } from '../../types/customMapMarker';
import { type ProductListItem } from '../../types/productListItem';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet/dist/leaflet.css';

const DEFAULT_MAP_CONFIG = {
    latBound: 89.981_557_606_466_17,
    langBound: 180,
    centerLat: 51.165_7,
    centerLang: 10.451_5,
    zoom: 6,
    maxBoundsViscosity: 1,
    zoomControl: false,
    urlTemplate: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    zoomEventType: 'zoomend dragend resize',
    maxTiles: 256,
    maxZoom: 15,
};

const DELAY_TIME = {
    zoomend: 500,
    dragend: 800,
    resize: 800,
};

const DELAY_TIME_MOBILE = {
    zoomend: 300,
    dragend: 600,
    resize: 600,
};

const messages = getProductTileMessages();

type MapViewProps = {
    productListItems: ProductListItem[];
    setHoveredProductId: (productId: string) => void;
    dispatchSearch: Dispatch<SearchReducerAction>;
    modalState: {
        isMapViewModalOpen: boolean;
        isSidePanelOpen: boolean;
        isDesktop: boolean;
    };
    hoveredProductIdState?: string;
    queryId?: string;
    indexName?: string;
};

const MapView = ({
    dispatchSearch,
    hoveredProductIdState,
    indexName,
    modalState,
    productListItems,
    queryId,
    setHoveredProductId,
}: MapViewProps): {
    mapRef: React.MutableRefObject<Map | null>;
    markerClusterRef: React.MutableRefObject<MarkerClusterGroup | null>;
    setMapView: (lat: number, lng: number, distance: number, locationName?: string) => void;
} => {
    const intl = useIntl();
    const mapRef = useRef<Map | null>(null);
    const markerClusterRef = useRef<MarkerClusterGroup | null>(null);
    const { customerState, errorLogger, featureFlagsVariation, initialWishlist } =
        useFragmentContext<FragmentContext>();
    const isDesktopRef = useRef(false);

    const defaultIcon = createIcon();
    const highlightedIcon = createIcon('hover');

    const { isDesktop, isSidePanelOpen } = modalState;

    const popUpMessages = {
        a11yCloseErrorMessage: intl.formatMessage(messages.a11yCloseErrorMessage),
        tooManyWishlistArticlesError: intl.formatMessage(messages.tooManyWishlistArticlesError),
        a11yPdpRedirectLabel: intl.formatMessage(messages.a11YPdpRedirection),
        screenReaderTextForWishlistRemove: intl.formatMessage(
            messages.screenReaderTextForWishlistRemove,
        ),
        screenReaderTextForWishlistAdd: intl.formatMessage(messages.screenReaderTextForWishlistAdd),
        closePopup: intl.formatMessage(messages.closePopup),
    };
    const locationNameRef = useRef<string | undefined>(undefined);

    let debounceTimeout: number;

    const sendRequest = (map: L.Map): void => {
        if (!getMapContainer()) return;

        const lat = map.getCenter().lat;
        const long = map.getCenter().lng;
        let type = SearchReducerActionType.MapBoundary;

        if (locationNameRef.current === 'reset') {
            type = SearchReducerActionType.LocationFilterReset;
            dispatchSearch({
                type,
            });
        } else if (!locationNameRef.current) {
            locationNameRef.current = getUrlParameters().locationName;
        }

        dispatchSearch({
            type,
            value: {
                lat,
                long,
                distance: getDistanceToEdgeFromCenter(map),
                name: locationNameRef.current,
            },
            boundary: {
                lat1: map.getBounds().getNorthWest().lat,
                lng1: map.getBounds().getNorthWest().lng,
                lat2: map.getBounds().getSouthEast().lat,
                lng2: map.getBounds().getSouthEast().lng,
            },
        });
        locationNameRef.current = '';
    };

    const debouncedMoveEnd = (map: L.Map, e: L.LeafletEvent): void => {
        if (!map || !getMapContainer()) return;
        const eventType = e.type as keyof typeof DELAY_TIME;
        const delayTime = isDesktopRef.current ? DELAY_TIME : DELAY_TIME_MOBILE;

        if (debounceTimeout !== undefined) {
            clearTimeout(debounceTimeout);
        }

        debounceTimeout = window.setTimeout(() => sendRequest(map), delayTime[eventType]);
    };

    const setMapView = (
        lat: number,
        lng: number,
        distanceInKm: number,
        locationName?: string,
    ): void => {
        const map = mapRef.current;
        locationNameRef.current = locationName;

        if (!map) return;

        if (lat === 0 && lng === 0) {
            locationNameRef.current = 'reset';
            map.flyTo(
                [DEFAULT_MAP_CONFIG.centerLat, DEFAULT_MAP_CONFIG.centerLang],
                DEFAULT_MAP_CONFIG.zoom,
            );
            return;
        }

        const bounds = calculateMapView(lat, lng, distanceInKm);
        const zoom = map.getBoundsZoom(bounds);
        map.flyTo([lat, lng], zoom);
    };

    const initializeMap = (): Map | null => {
        if (mapRef.current) {
            return mapRef.current;
        }

        const container = getMapContainer();
        if (!container) return null;
        const southWest = latLng(
            DEFAULT_MAP_CONFIG.latBound * -1,
            DEFAULT_MAP_CONFIG.langBound * -1,
        );
        const northEast = latLng(DEFAULT_MAP_CONFIG.latBound, DEFAULT_MAP_CONFIG.langBound);
        const maxBounds = latLngBounds(southWest, northEast);

        const map = Lmap(container, {
            center: [DEFAULT_MAP_CONFIG.centerLat, DEFAULT_MAP_CONFIG.centerLang],
            zoom: DEFAULT_MAP_CONFIG.zoom,
            zoomControl: DEFAULT_MAP_CONFIG.zoomControl,
            maxBounds,
            maxBoundsViscosity: DEFAULT_MAP_CONFIG.maxBoundsViscosity,
        });
        map.setMinZoom(
            Math.ceil(
                Math.log2(Math.max(map.getSize().x, map.getSize().y) / DEFAULT_MAP_CONFIG.maxTiles),
            ),
        );

        map.setMaxZoom(DEFAULT_MAP_CONFIG.maxZoom);
        mapRef.current = map;

        map.on(DEFAULT_MAP_CONFIG.zoomEventType, e => debouncedMoveEnd(map, e));
        map.addControl(
            new Control.Zoom({ position: isDesktopRef.current ? 'bottomright' : 'topleft' }),
        );
        tileLayer(DEFAULT_MAP_CONFIG.urlTemplate).addTo(map);

        return map;
    };

    useEffect(() => {
        isDesktopRef.current = isDesktop;
    }, [isDesktop]);

    useEffect(() => {
        setTimeout(() => {
            const mapContainer = mapRef.current ?? initializeMap();

            markerClusterRef.current = markerClusterRef.current ?? createMarkerCluster();

            if (mapContainer)
                addMarkersToCluster(
                    {
                        map: mapContainer,
                        markers: markerClusterRef.current,
                        productListItems,
                        queryId,
                        indexName,
                    },

                    popUpMessages,
                    intl,
                    { customerState, errorLogger, initialWishlist },
                    { isSidePanelOpen, isDesktop: isDesktopRef.current },
                    featureFlagsVariation,
                );

            const urlParameter = getUrlParameters();

            const { distance = 0, lat = 0, long = 0 } = urlParameter;
            setMapView(lat, long, distance);

            return () => {
                cleanupMapRef(mapRef);
            };
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useDeepCompareEffect(() => {
        setTimeout(() => {
            if (!markerClusterRef.current || !mapRef.current || !getMapContainer()) return;
            markerClusterRef.current.clearLayers();
            markerClusterRef.current = createMarkerCluster();
            addMarkersToCluster(
                {
                    map: mapRef.current,
                    markers: markerClusterRef.current,
                    productListItems,
                    queryId,
                    indexName,
                },
                popUpMessages,
                intl,
                { customerState, errorLogger, initialWishlist },
                { isSidePanelOpen, isDesktop: isDesktopRef.current },
                featureFlagsVariation,
            );
            markerClusterRef.current.eachLayer(layer => {
                if ('productID' in layer) {
                    const marker = layer as CustomMapMarker;
                    const shouldBeHighlighted = marker.productID === hoveredProductIdState;
                    marker.setIcon(shouldBeHighlighted ? highlightedIcon : defaultIcon);
                    marker.isHovered = shouldBeHighlighted;
                }
            });

            markerClusterRef.current.refreshClusters();
        }, 0);
    }, [hoveredProductIdState, productListItems]);

    useDeepCompareEffect(() => {
        setHoveredProductId('');
    }, [productListItems]);

    return { mapRef, markerClusterRef, setMapView };
};

export { MapView };
